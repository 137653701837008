import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutService} from '../../../../services/layout.service';

@Component({
  selector: 'app-management-layout',
  templateUrl: './management-layout.component.html',
  styleUrls: ['./management-layout.component.css']
})
export class ManagementLayoutComponent implements OnInit {

  idUser: number;
  layouts: any = [];

  constructor(private route: ActivatedRoute, private router: Router, private layoutService: LayoutService) {
    this.idUser = +this.route.snapshot.paramMap.get('idUser');
  }

  ngOnInit() {
    this.initData();
  }

  async initData() {
    // this.layouts = await this.layoutService.getAllLayouts();
  }

  editLayout(id) {
    this.router.navigate(['/admin/layout/edit', this.idUser, id]);
  }

  createLayout() {
    this.router.navigate(['/admin/layout/create', this.idUser]);
  }

}
