import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LayoutService} from '../../../../services/layout.service';
import Swal from 'sweetalert2';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
  selector: 'app-create-layout',
  templateUrl: './create-layout.component.html',
  styleUrls: ['./create-layout.component.css']
})
export class CreateLayoutComponent implements OnInit {

  idUser: number;
  layout = ``;

  constructor(private layoutService: LayoutService, private route: ActivatedRoute, private ngxService: NgxUiLoaderService) {
    this.idUser = + this.route.snapshot.paramMap.get('idUser');
  }

  ngOnInit() {
    this.ngxService.stop();
  }

  getData(data: any) {
    this.layout = data;
  }

  async getParams() {
    const totalParams: any = this.layout.split('%%');
    const validator = /^[0-9a-zA-Z]+$/;
    const paramsC: any = [];
    const paramsE: any = [];
    let params = '';
    let error = '';

    for (let x = 1; x < totalParams.length;) {
      if (totalParams[x].toUpperCase() === 'LIGA') {
        this.genericModal(`El elemento <strong>"${totalParams[x]}"</strong> no puede ser agregado como parámetro.`);
        return false;
      }
      if (totalParams[x].match(validator)) {
        if (x !== 1) {
          params += ', ';
        }
        params += totalParams[x];
        paramsC.push(totalParams[x]);
      } else {
        if (paramsE.length !== 0) {
          error += ', ';
        }
        error += totalParams[x];
        paramsE.push(totalParams[x]);
      }
      x += 2;
    }

    if (paramsE.length !== 0) {
      this.genericModal(`Los parámetros <strong>"${error}"</strong> no puede ser agregados. Favor de incluir solo letras y números.`);
      return false;
    }

    if (paramsC.length !== 0) {
      const response = await Swal.fire({
        title: 'Recuerde',
        html: `Usted está agregado los siguentes parámetros <strong>"${params}"</strong>`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      });

      if (!!response.value) {
        return paramsC;
      }
    } else {
      return paramsC;
    }
  }

  async confirmLayout() {
    if (this.layout !== '') {
      const {value: nameLayout} = await Swal.fire({
        title: 'Nombre',
        input: 'text',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return '¡Error! Ingrese un nombre';
          }
        }
      });

      if (nameLayout) {
        const params: any = await this.getParams();
        if (params) {
          this.saveLayout(nameLayout, params);
        }
      }
    }
  }

  async saveLayout(nameLayout, paramsT) {
    try {
      this.ngxService.start();
      const data = {
        idUser: this.idUser,
        layout: btoa(unescape(encodeURIComponent(JSON.stringify(this.layout)))),
        name: nameLayout,
        paramsT: paramsT.length
      };

      const encoded = {data: btoa(unescape(encodeURIComponent(JSON.stringify(data))))};
      const saved: any = await this.layoutService.saveLayout(encoded);
      this.ngxService.stop();
      if (saved.data.success) {
        const response = await Swal.fire({
          title: 'Realizado',
          html: `¡Se ha guardado con éxito!`,
          type: 'success',
          confirmButtonText: 'Aceptar'
        });

        if (!!response.value) {
          window.location.reload();
        }
      }
    } catch (e) {
      this.saveLayout(nameLayout, paramsT);
    }
  }

  genericModal(message) {
    Swal.fire({
      title: 'Error',
      html: message,
      type: 'warning',
      confirmButtonText: 'Aceptar',
    });
  }
}
