import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {CONFIG} from '../../../../config';
import * as S3 from 'aws-sdk/clients/s3';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {NgxUiLoaderService} from 'ngx-ui-loader';

declare var $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  @Input() idUser: number;
  @Input() layout = ``;
  @Output() layoutData = new EventEmitter<string>();

  imgSizeForm: FormGroup;
  file: any;

  constructor(private ngxService: NgxUiLoaderService) {

  }

  ngOnInit() {
    this.imgSizeForm = new FormGroup({
      width: new FormControl('', [Validators.required, Validators.minLength(1)]),
      height: new FormControl('', [Validators.required, Validators.minLength(1)])
    });
  }

  setData() {
    this.layoutData.emit(this.layout);
  }

  openModal(ev) {
    this.file = ev.target.files[0];
    ev.target.value = '';
    ev.preventDefault();
  }

  async uploadS3() {
    if (this.imgSizeForm.valid) {
      this.ngxService.start();
      const imgConfig = this.imgSizeForm.value;
      const contentType = this.file.type;
      const aws = new S3(CONFIG.AWS_CONFIG.credentials);
      const params = {
        Bucket: CONFIG.AWS_CONFIG.bucket,
        Key: `LayoutConekta/${this.idUser}/${this.file.name}`,
        Body: this.file,
        ACL: 'public-read',
        ContentType: contentType
      };

      aws.upload(params, (err, data) => {
        if (err) {
          console.log(err);
          return false;
        }
        this.layout += `<img src="${data.Location}" width="${imgConfig.width}px" height="${imgConfig.height}px"></img>`;
        this.imgSizeForm.reset();
        $('#imgSize').modal('hide');
      });
      this.ngxService.stop();
    }
  }

}
