import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EditorModule } from '@progress/kendo-angular-editor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap';
import { ColorSketchModule } from 'ngx-color/sketch';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import {NgxUiLoaderConfig, NgxUiLoaderModule} from 'ngx-ui-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoadingComponent } from './views/loading/loading.component';
import { CreateLayoutComponent } from './views/admin/layout/create-layout/create-layout.component';
import { CreateEmailComponent } from './views/admin/create-email/create-email.component';
import { LayoutComponent } from './views/admin/layout/layout/layout.component';
import { ManagementLayoutComponent } from './views/admin/layout/management-layout/management-layout.component';
import { EditLayoutComponent } from './views/admin/layout/edit-layout/edit-layout.component';
import { ManagementComponent } from './views/admin/notifications/management/management.component';
import { StatusComponent } from './views/admin/notifications/status/status.component';
import { UnsubscribeComponent } from './views/admin/unsubscribe/unsubscribe.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#ff592a',
  pbColor: '#ff592a'
};

@NgModule({
  declarations: [
    AppComponent,
    CreateLayoutComponent,
    LoadingComponent,
    CreateEmailComponent,
    LayoutComponent,
    ManagementLayoutComponent,
    EditLayoutComponent,
    ManagementComponent,
    StatusComponent,
    UnsubscribeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    EditorModule,
    HttpClientModule,
    ColorSketchModule,
    SelectDropDownModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SweetAlert2Module.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
