import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  APIURL: any = environment.ENDPOINTS;

  constructor(private http: HttpClient) { }

  getNotifications(data) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.APIURL.NOTIFICATIONS}/getNotifications`, data).subscribe((tasks) => {
        resolve(tasks);
      }, (err) => {
        reject(err);
      });
    });
  }

  getUsers(data) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.APIURL.NOTIFICATIONS}/getUsers`, data).subscribe((tasks) => {
        resolve(tasks);
      }, (err) => {
        reject(err);
      });
    });
  }
}
