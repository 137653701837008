export const CONFIG = {
  AWS_CONFIG: {
    credentials: {
      accessKeyId: 'AKIAWWOA6GWZHO2ZILWE',
      secretAccessKey: '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',
      region: 'us-east-1'
    },
    bucket: 'elasticbeanstalk-us-east-1-460503004594'
  },
  SELECT_CONFIG: {
    template: {
      displayKey: 'name',
      search: true,
      height: 'auto',
      placeholder: 'Template',
      moreText: 'more',
      noResultsFound: 'Sin resultados',
      searchPlaceholder: 'Buscar',
      searchOnKey: 'name'
    },
    poll: {
      displayKey: 'name',
      search: true,
      height: 'auto',
      placeholder: 'Encuesta',
      moreText: 'more',
      noResultsFound: 'Sin resultados',
      searchPlaceholder: 'Buscar',
      searchOnKey: 'name'
    }
  },
  DATATABLE_CONFIG: {
    language: {
      lengthMenu: 'Mostrar _MENU_ registros por página',
      zeroRecords: 'No hay registros',
      info: 'Mostrando _PAGE_ de _PAGES_',
      infoEmpty: 'No hay registros',
      infoFiltered: '(filtered from _MAX_ total records)',
      search: 'Buscar',
      paginate: {
        previous: 'Atras',
        next: 'Adelante'
      }
    }
  }
};
