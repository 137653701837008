export const environment = {
  env: 'DEV',
  production: false,
  ENDPOINTS: {
    TEMPLATE: 'http://192.168.2.55:8000/email/template',
    POLL: 'http://192.168.2.55:8000/email/encuesta',
    NOTIFICATIONS: 'http://192.168.2.55:8000/email/notifications',
    UNSUBSCRIBE: 'http://192.168.2.55:8000/email/subscription'
  }
};
