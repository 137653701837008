import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutService} from '../../../../services/layout.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-edit-layout',
  templateUrl: './edit-layout.component.html',
  styleUrls: ['./edit-layout.component.css']
})
export class EditLayoutComponent implements OnInit {

  idUser: number;
  idLayout: number;
  layout = ``;
  template: any = [];

  constructor(private route: ActivatedRoute, private router: Router, private layoutService: LayoutService) {
    this.idUser = +this.route.snapshot.paramMap.get('idUser');
    this.idLayout = +this.route.snapshot.paramMap.get('idLayout');
  }

  ngOnInit() {
    this.initData();
  }

  async initData() {
    // this.template = await this.layoutService.getLayout({idUser: this.idUser});
  }

  getLayout(data: any) {
    this.layout = data;
  }

  async getParams() {
    const totalParams: any = this.layout.split('%%');
    const validator = /^[0-9a-zA-Z]+$/;
    const paramsC: any = [];
    const paramsE: any = [];
    let params = '';
    let error = '';

    for (let x = 1; x < totalParams.length;) {
      if (totalParams[x].toUpperCase() === 'LIGA') {
        this.genericModal(`El elemento <strong>"${totalParams[x]}"</strong> no puede ser agregado como parámetro.`);
        return false;
      }
      if (totalParams[x].match(validator)) {
        if (x !== 1) {
          params += ', ';
        }
        params += totalParams[x];
        paramsC.push(totalParams[x]);
      } else {
        if (paramsE.length !== 0) {
          error += ', ';
        }
        error += totalParams[x];
        paramsE.push(totalParams[x]);
      }
      x += 2;
    }

    if (paramsE.length !== 0) {
      this.genericModal(`Los parámetros <strong>"${error}"</strong> no puede ser agregados. Favor de incluir solo letras y números.`);
      return false;
    }

    if (paramsC.length !== 0) {
      const response = await Swal.fire({
        title: 'Recuerde',
        html: `Usted está agregado los siguentes parámetros <strong>"${params}"</strong>`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      });

      if (!!response.value) {
        return paramsC;
      }
    } else {
      return paramsC;
    }
  }

  async saveLayout() {
    if (this.layout !== '') {
      const {value: nameLayout} = await Swal.fire({
        title: 'Nombre',
        input: 'text',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return '¡Error! Ingrese un nombre';
          }
        }
      });

      if (nameLayout) {
        const paramsT: any = await this.getParams();
        if (paramsT) {
          const data = {
            idLayout: this.template.id,
            layout: this.layout,
            idUser: this.idUser,
            name: nameLayout,
            params: paramsT
          };

          // const saved: any = await this.layoutService.editLayout(data);
        }
      }
    }
  }

  genericModal(message) {
    Swal.fire({
      title: 'Error',
      html: message,
      type: 'warning',
      confirmButtonText: 'Aceptar',
    });
  }

  back() {
    this.router.navigate(['/admin/layout/management', this.idUser]);
  }

}
