import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UnsubscribeService} from '../../../services/unsubscribe.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

  user: any;
  unsubscribeForm: FormGroup;

  constructor(private route: ActivatedRoute, private ngxService: NgxUiLoaderService, private unsubscribeService: UnsubscribeService) {
    // console.log(this.user)
    this.user = this.route.snapshot.paramMap.get('user');
  }

  ngOnInit() {
    this.unsubscribeForm = new FormGroup({
      user: new FormControl(this.user, [Validators.required]),
      item: new FormControl(54),
      subItem: new FormControl(2),
      reason: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(200)]),
    });
  }

  async unsubscribe() {
    try {
      this.ngxService.start();
      const encoded = {data: btoa(unescape(encodeURIComponent(JSON.stringify(this.unsubscribeForm.value))))};
      const response: any = await this.unsubscribeService.unsubscribe(encoded);

      this.ngxService.stop();
      if (response.data.success) {
        Swal.fire({
          title: 'Realizado',
          html: `¡Se ha cancelado la suscripción¡`,
          type: 'success',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          window.close();
        });
      }
    } catch (e) {
      this.unsubscribe();
    }
  }
}
