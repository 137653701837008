import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsService} from '../../../../services/notifications.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {CONFIG} from '../../../../config';

declare var $: any;

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent implements OnInit {

  notifications: any = [];
  idUser: number;

  constructor(private router: Router, private route: ActivatedRoute, private notificationsService: NotificationsService,
              private ngxService: NgxUiLoaderService) {
    this.idUser = +this.route.snapshot.paramMap.get('idUser');
  }

  ngOnInit() {
    this.ngxService.start();
    this.initData();
  }

  async initData() {
    try {
      const idUser = { idUser: this.idUser };
      const encoded = {data: btoa(unescape(encodeURIComponent(JSON.stringify(idUser))))};
      const response: any = await this.notificationsService.getNotifications(encoded);
      this.notifications = response.data.notifications;
      setTimeout(() => {
        $('#table').DataTable(CONFIG.DATATABLE_CONFIG);
      }, 100);

      this.ngxService.stop();
    } catch (e) {
      this.initData();
    }
  }

  statusView(idNotification, status) {
    this.router.navigate(['/admin/notifications/status', this.idUser, idNotification, status]);
  }

}
