import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  APIURL: any = environment.ENDPOINTS;

  constructor(private http: HttpClient) { }

  unsubscribe(data) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.APIURL.UNSUBSCRIBE}/unsubscribe`, data).subscribe((layout) => {
        resolve(layout);
      }, (err) => {
        reject(err);
      });
    });
  }
}
