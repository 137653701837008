import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateLayoutComponent } from './views/admin/layout/create-layout/create-layout.component';
import { LoadingComponent } from './views/loading/loading.component';
import { CreateEmailComponent } from './views/admin/create-email/create-email.component';
import { EditLayoutComponent } from './views/admin/layout/edit-layout/edit-layout.component';
import { ManagementLayoutComponent } from './views/admin/layout/management-layout/management-layout.component';
import { ManagementComponent } from './views/admin/notifications/management/management.component';
import { StatusComponent } from './views/admin/notifications/status/status.component';
import { UnsubscribeComponent } from './views/admin/unsubscribe/unsubscribe.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: LoadingComponent
  },
  {
    path: 'admin',
    children: [
      {
        path: 'layout',
        children: [
          {
            path: 'create/:idUser',
            component: CreateLayoutComponent
          },
          {
            path: 'edit/:idUser/:idLayout',
            component: EditLayoutComponent
          },
          {
            path: 'management/:idUser',
            component: ManagementLayoutComponent
          }
        ]
      },
      {
        path: 'email',
        children: [
          {
            path: 'create/:idUser',
            component: CreateEmailComponent
          }
        ]
      },
      {
        path: 'notifications',
        children: [
          {
            path: 'management/:idUser',
            component: ManagementComponent
          },
          {
            path: 'status/:idUser/:idNotification/:status',
            component: StatusComponent
          }
        ]
      },
      {
        path: 'unsubscribe/:user',
        component: UnsubscribeComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
