import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsService} from '../../../../services/notifications.service';
import {CONFIG} from '../../../../config';
import {NgxUiLoaderService} from 'ngx-ui-loader';

declare var $: any;

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {

  idNotification: number;
  idUser: number;
  status: string;
  users: any = [];
  usersToResend: any = [];

  constructor(private route: ActivatedRoute, private router: Router, private notificationsService: NotificationsService,
              private ngxService: NgxUiLoaderService) {
    this.idNotification = +this.route.snapshot.paramMap.get('idNotification');
    this.idUser = +this.route.snapshot.paramMap.get('idUser');
    this.status = this.route.snapshot.paramMap.get('status');
  }

  ngOnInit() {
    this.ngxService.start();
    this.initData();
  }

  async initData() {
    const data = {idNotification: this.idNotification, status: this.status};
    const encoded = {data: btoa(unescape(encodeURIComponent(JSON.stringify(data))))};
    const response: any = await this.notificationsService.getUsers(encoded);
    this.users = response.data.users;
    setTimeout(() => {
      $('#table').DataTable(CONFIG.DATATABLE_CONFIG);
    }, 100);
    this.ngxService.stop();
  }

  resent(ev, data) {
    if (ev.target.checked) {
      this.usersToResend.push(data.user);
    } else {
      const index = this.usersToResend.findIndex(item => item.id === data.id);
      this.usersToResend.splice(index, 1);
    }
  }

  async confirmSend() {
    const notification = {
      idNotification: this.idNotification,
      users: this.usersToResend
    };
    window.parent.postMessage({data: notification}, '*');
    this.back();
  }

  back() {
    this.router.navigate(['/admin/notifications/management', this.idUser]);
  }
}
