import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LayoutService} from '../../../services/layout.service';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import {ColorEvent} from 'ngx-color';
import * as S3 from 'aws-sdk/clients/s3';
import {CONFIG} from '../../../config';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
  selector: 'app-create-email',
  templateUrl: './create-email.component.html',
  styleUrls: ['./create-email.component.css']
})
export class CreateEmailComponent implements OnInit {

  idUser: number;
  layout = ``;
  color = '#ff592a';
  file: any;
  emilForm: FormGroup;
  pollForm: FormGroup;
  configTemplate = CONFIG.SELECT_CONFIG.template;
  configPoll = CONFIG.SELECT_CONFIG.poll;
  templateOpt: any = [];
  templateSelected: boolean;
  pollOpt: any = [];
  pollSelected: boolean;
  template = '';
  excelParams: any = [];
  link: any = `<a href='%%liga%%' style='color: #0000FF; margin:10px auto;'>Da clic aquí para contestar la encuesta</a>`;
  subscription: any = `<br><br><br><a href='http://upx-components-adm.s3-website-us-east-1.amazonaws.com/#/admin/unsubscribe/%%usrSuscripcion%%' style='color: #FF0000; margin:10px auto;'>Cancelar suscripción</a>`;

  constructor(private layoutService: LayoutService, private route: ActivatedRoute, private ngxService: NgxUiLoaderService) {
    console.log(this.idUser)
    this.idUser = +this.route.snapshot.paramMap.get('idUser');
  }

  ngOnInit() {
    this.ngxService.start();
    this.emilForm = new FormGroup({
      template: new FormControl(''),
      title: new FormControl('', [Validators.required, Validators.minLength(1)]),
      alias: new FormControl('', [Validators.required, Validators.minLength(1)]),
      encuesta: this.pollForm = new FormGroup({
        id: new FormControl(''),
        logo: new FormControl(''),
        color: new FormControl('#ff592a')
      })
    });

    this.bindEvent(window, 'message', (e) => {
      if (e.data.params) {
        this.excelParams = e.data.params;
      }
      if (e.data.clean) {
        window.location.reload();
      }
      if (e.data.open) {
        this.layout = this.layout.replace(this.subscription, '');
        this.layout = this.layout.replace(this.link, '');
      }
      if (e.data.doSave) {
        this.confirmSave();
      }
      if (e.data.continue) {
        if (this.emilForm.valid) {
          this.confirmEmail();
        }
      }
    });

    this.initData();
  }

  bindEvent(element, eventName, eventHandler) {
    if (element.addEventListener) {
      element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
      element.attachEvent('on' + eventName, eventHandler);
    }
  }

  formStatus() {
    if (this.emilForm.valid) {
      window.parent.postMessage({formValid : false}, '*');
    } else {
      window.parent.postMessage({formValid: true}, '*');
    }
  }

  async initData() {
    try {
      const data = {idUser: this.idUser};
      const encoded = {data: btoa(unescape(encodeURIComponent(JSON.stringify(data))))};
      const templateDate: any = await this.layoutService.getTemplates(encoded);
      const pollData: any = await this.layoutService.getPolls(encoded);
      this.templateOpt = templateDate.data.template;
      this.pollOpt = pollData.data.encuesta;

      this.ngxService.stop();
    } catch (e) {
      this.initData();
    }
  }

  getLayout(data: any) {
    this.layout = data;
  }

  getTemplate(ev) {
    this.emilForm.patchValue({template: ev.value ? ev.value.idTemplate : null});
    this.templateSelected = ev.value ? true : false;
    this.template = ev.value ? JSON.parse(decodeURIComponent(escape(atob(ev.value.body)))) : '';
    this.layout = ev.value ? JSON.parse(decodeURIComponent(escape(atob(ev.value.body)))) : '';
  }

  getPoll(ev) {
    this.pollForm.patchValue({id: ev.value ? ev.value.idEncuesta : null});
    this.pollSelected = !!ev.value;
  }

  colorSelect($event: ColorEvent) {
    this.color = $event.color.hex;
    this.pollForm.patchValue({color: $event.color.hex});
  }

  async uploadS3(ev) {
    try {
      this.ngxService.start();
      this.file = ev.target.files[0];
      const contentType = this.file.type;
      const aws = new S3(CONFIG.AWS_CONFIG.credentials);
      const params = {
        Bucket: CONFIG.AWS_CONFIG.bucket,
        Key: `LayoutConekta/${this.idUser}/${this.file.name}`,
        Body: this.file,
        ACL: 'public-read',
        ContentType: contentType
      };

      aws.upload(params, (err, data) => {
        if (err) {
          console.log(err);
          return false;
        }
        this.pollForm.patchValue({logo: data.Location});
        this.ngxService.stop();
      });

      ev.preventDefault();
    } catch (e) {
      this.uploadS3(ev);
    }
  }

  async confirmEmail() {
    const paramsT = await this.getParams();
    if (this.pollSelected) {
      this.layout += this.link;
    }
    if (paramsT) {
      this.layout += this.subscription;
      const dataEmail = {
        ...this.emilForm.value,
        message: btoa(unescape(encodeURIComponent(JSON.stringify(this.layout)))),
        template_modified: this.templateSelected ? this.template !== this.layout : null,
        params: paramsT
      };

      window.parent.postMessage({data: dataEmail}, '*');
    }
  }

  async confirmSave() {
    if (this.layout !== '') {
      const {value: nameLayout} = await Swal.fire({
        title: 'Nombre',
        input: 'text',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return '¡Error! Ingrese un nombre';
          }
        }
      });

      if (nameLayout) {
        const params: any = await this.getParams();
        if (params) {
          this.saveLayout(nameLayout, params);
        }
      }
    }
  }

  async saveLayout(nameLayout, params) {
    try {
      this.ngxService.start();
      const data = {
        idUser: this.idUser,
        layout: btoa(unescape(encodeURIComponent(JSON.stringify(this.layout)))),
        name: nameLayout,
        paramsT: params.length
      };

      const encoded = {data: btoa(unescape(encodeURIComponent(JSON.stringify(data))))};
      const saved: any = await this.layoutService.saveLayout(encoded);
      this.ngxService.stop();
      if (saved.data.success) {
        Swal.fire({
          title: 'Realizado',
          html: `¡Se ha guardado con éxito!`,
          type: 'success',
          confirmButtonText: 'Aceptar'
        });
      }
    } catch (e) {
      this.saveLayout(nameLayout, params);
    }
  }

  async getParams() {
    const totalParams: any = this.layout.split('%%');
    const validator = /^[0-9a-zA-Z]+$/;
    const paramsC: any = [];
    const paramsE: any = [];
    let params = '';
    let error = '';

    for (let x = 1; x < totalParams.length;) {
      if (totalParams[x].toUpperCase() === 'LIGA' || totalParams[x].toUpperCase() === 'usrSuscripcion') {
        this.genericModal(`El elemento <strong>"${totalParams[x]}"</strong> no puede ser agregado como parámetro.`);
        return false;
      }
      if (totalParams[x].match(validator)) {
        if (x !== 1) {
          params += ', ';
        }
        params += totalParams[x];
        paramsC.push(totalParams[x]);
      } else {
        if (paramsE.length !== 0) {
          error += ', ';
        }
        error += totalParams[x];
        paramsE.push(totalParams[x]);
      }
      x += 2;
    }

    if (paramsE.length !== 0) {
      this.genericModal(`Los parámetros <strong>"${error}"</strong> no puede ser agregados. Favor de incluir solo letras y números.`);
      return false;
    }

    if (paramsC.length !== 0) {
      const response = await Swal.fire({
        title: 'Recuerde',
        html: `Usted está agregado los siguentes parámetros <strong>"${params}"</strong>`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar'
      });

      if (!!response.value) {
        return paramsC;
      }
    } else {
      return paramsC;
    }
  }

  genericModal(message) {
   Swal.fire({
      title: 'Error',
      html: message,
      type: 'warning',
      confirmButtonText: 'Aceptar',
    });
  }
}
