import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  APIURL: any = environment.ENDPOINTS;

  constructor(private http: HttpClient) { }

  saveLayout(data) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.APIURL.TEMPLATE}/saveTemplate`, data).subscribe((layout) => {
        resolve(layout);
      }, (err) => {
        reject(err);
      });
    });
  }

  getPolls(data) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.APIURL.POLL}/getEncuesta`, data).subscribe((polls) => {
        resolve(polls);
      }, (err) => {
        reject(err);
      });
    });
  }

  getTemplates(data) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.APIURL.TEMPLATE}/getTemplate`, data).subscribe((layouts) => {
        resolve(layouts);
      }, (err) => {
        reject(err);
      });
    });
  }
}
